import helper from "./helper";

import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
window.Chart = Chart;

import * as DateLuxon from "luxon";
//
window.DateLuxon = DateLuxon;
//
window.newDateString = (days) => {
    return DateLuxon.DateTime.now().minus({days}).toISO();
};

window.newDate = (days) => {
    return DateLuxon.DateTime.now().minus({days}).toJSDate();
};

window.parseISODate = (str) => {
    return DateLuxon.DateTime.fromISO(str);
};

window.getFilterLabels = () => {
    const actualDate = DateLuxon.DateTime.now();

    const labels = [];
    // labels['lastMinutes'] = [];
    // labels['lastHours'] = [];
    // labels['lastDaysOfWeek'] = [];
    // labels['lastDaysOfMonth'] = [];
    labels['lastMonthsText'] = [];
    labels['lastMonths'] = [];

    // for (let i = 59; i >= 0; i--) {
    //     labels['lastMinutes'].push(actualDate.minus({ minutes: i }).toFormat("T"));
    // }
    //
    // for (let i = 23; i >= 0; i--) {
    //     labels['lastHours'].push(actualDate.minus({ hours: i }).toFormat("HH:00"));
    // }
    //
    // for (let i = 6; i >= 0; i--) {
    //     labels['lastDaysOfWeek'].push(actualDate.minus({ days: i }).toFormat("d.M"));
    // }
    //
    // for (let i = 30; i >= 0; i--) {
    //     labels['lastDaysOfMonth'].push(actualDate.minus({ days: i }).toFormat("d.M"));
    // }

    for (let i = 11; i >= 0; i--) {
        labels['lastMonthsText'].push(actualDate.minus({ months: i }).toFormat("LLLL"));
        labels['lastMonths'].push(actualDate.minus({ months: i }));
    }


    return labels;
};
