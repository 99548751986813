// core version + navigation, pagination modules:
import Swiper from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

window.Swiper = Swiper;


// const swiper = new Swiper(".testimonials-swiper", {
//     modules: [ Autoplay ],
//     loop: true,
//     slidesPerView: 1,
//     spaceBetween: 0,
//     grabCursor: true,
//     autoplay: {
//         delay: 1000,
//         disableOnInteraction: false,
//     },
// });
