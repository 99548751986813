import "./components/mobileMenu";
import "./components/header";
import "./components/heroAnimation";
import "./components/scroll";
import "./components/toastify";
import "./components/clipboard";
import "./components/swiper";

import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/modal";
// import "clipboard/dist/clipboard.min";

import '@fortawesome/fontawesome-free/js/all';

import './components/chart-helper';

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();
