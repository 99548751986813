import ClipboardJS from "clipboard";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const clipboard = new ClipboardJS('.btn-clipboard');

clipboard.on('success', function (e) {
    let elBtn = document.getElementById(e.trigger.getAttribute('id'));
    if (elBtn) {
        const tippyEl = tippy(elBtn, {
            content: 'Copied',
            showOnCreate: true,
            duration: [100, 3000],
        });

        setTimeout(function(){
            tippyEl.destroy();
        }, 1500);
    }
});
