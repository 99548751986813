const menuOpener = document.querySelector('label[for="menu-opener"] input');
const mobileMenu = document.querySelector('header nav');


menuOpener.addEventListener('click', function() {
    if (this.checked) {
        mobileMenu.classList.add('open');
    } else {
        mobileMenu.classList.remove('open');
    }
});
