/* HIDE HEADER ON SCROLL */
const header = document.querySelector('header');
let prevScrollPosition = scrollY;

window.addEventListener('scroll', function() {
    let currentScrollPosition = scrollY;

    if (scrollY > 0) {
        header.classList.add('smaller');
    } else {
        header.classList.remove('smaller');
    }
    if ((currentScrollPosition > 200) && (prevScrollPosition < currentScrollPosition)) {
        header.classList.add('hide');
    } else {
        header.classList.remove('hide');
    }
    prevScrollPosition = currentScrollPosition
});
