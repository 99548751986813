import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

/**
 * Set active class to menu items on scrolling
 */

const menuItems = document.querySelectorAll('nav li a');
const sections = document.querySelectorAll('section:not(.hero)');

function setActiveClass() {
    let currentSection;
    sections.forEach(section => {
        const sectionTop = section.offsetTop;
        if (scrollY >= sectionTop - 200) {
            currentSection = section.getAttribute("id");
        }
    })


    menuItems.forEach(item=> {
        item.parentElement.classList.remove('active');
        const attr = item.getAttribute('href')
        if(attr.includes(`#${currentSection}`)) {
            item.parentElement.classList.add('active');
        }
    })
}

window.addEventListener('scroll', setActiveClass);


/**
 * Scroll animations
 */

ScrollTrigger.matchMedia({
    "(min-width: 800px)": function() {


gsap.fromTo('.hero h1', {opacity: 0,translateY:'-70'}, {opacity:1, translateY: 0, duration: 1, delay:0.3})
gsap.fromTo('.hero .base-btn', {opacity: 0,translateY:70}, {opacity:1, translateY: 0, duration: 1, delay:0.3})
gsap.fromTo('.hero h2', {opacity: 0,translateX:70}, {opacity:1, translateX: 0, duration: 1, delay:0.3})


/**
 * Heading 3
 */

const sectionsH3 = gsap.utils.toArray(document.querySelectorAll('h3'));

sectionsH3.forEach(heading => {
    const sectionH3 = gsap.timeline({
        scrollTrigger: {
            trigger: heading,
            start: 'bottom 90%',
            end: 'bottom 60%',
            scrub: true,
            once: true
        }
    })

    sectionH3.fromTo(heading, {
        opacity: 0,
        translateY: '-30px',
    },{opacity:1, translateY: 0,stagger: 0.1})

})

/**
 * HOW IT WORKS
 */


const howItWorksP = gsap.timeline({
    scrollTrigger: {
        trigger: '#how-it-works .col-lg-20 p',
        start: 'bottom 90%',
        end: 'bottom 60%',
        scrub: true,
        once: true
    },
})

howItWorksP.fromTo('#how-it-works .col-lg-20 p', {
    translateX: '-50px',
    opacity: 0,
}, {
    translateX: '0',
    opacity: 1
})


const howItWorksBtn = gsap.timeline({
    scrollTrigger: {
        trigger: '#how-it-works .base-btn',
        start: 'bottom 90%',
        end: 'bottom 70%',
        scrub: true,
        once: true
    },
})

howItWorksBtn.fromTo('#how-it-works .base-btn', {
    translateY: '50px',
    opacity: 0,
}, {
    translateY: '0',
    opacity: 1
})

const howItWorksBoxes = gsap.timeline({
    scrollTrigger: {
        trigger: '#how-it-works .col-lg-30',
        start: 'bottom 90%',
        end: 'bottom 60%',
        scrub: true,
        once: true
    },
})

howItWorksBoxes.fromTo('#how-it-works .col-lg-30', {
    translateY: '50px',
    opacity: 0,
}, {
    translateY: '0',
    opacity: 1,
    stagger: 0.2
})

const sectionSubtitle = gsap.utils.toArray(document.querySelectorAll('.subtitle'));

sectionSubtitle.forEach(subtitle => {
    const sectionSubtitle = gsap.timeline({
        scrollTrigger: {
            trigger: subtitle,
            start: 'bottom 90%',
            end: 'bottom 75%',
            scrub: true,
            once: true
        }
    })

    sectionSubtitle.fromTo(subtitle, {
        opacity: 0,
        translateY: '30px',
    },{opacity:1, translateY: 0})
})


const benefitBoxes = gsap.timeline({
    scrollTrigger: {
        trigger: '.benefit-box',
        start: 'bottom 90%',
        end: 'bottom 60%',
        scrub: true,
        once: true
    },
})

benefitBoxes.fromTo('.benefit-box', {
    translateY: '-50px',
    opacity: 0,
}, {
    translateY: '0',
    opacity: 1,
    stagger: 0.2
})

const benefitBtn = gsap.timeline({
    scrollTrigger: {
        trigger: '#benefits .base-btn',
        start: 'bottom 90%',
        end: 'bottom 70%',
        scrub: true,
        once: true
    },
})

benefitBtn.fromTo('#benefits .base-btn', {
    opacity: 0,
}, {
    opacity: 1,
})



const resultsP = gsap.timeline({
    scrollTrigger: {
        trigger: '#results p',
        start: 'bottom 90%',
        end: 'bottom 70%',
        scrub: true,
        once: true
    },
})

resultsP.fromTo('#results p', {
    opacity: 0,
    translateX: '-50px'
}, {
    opacity: 1,
    translateX: '0'
})

const resultCard = gsap.timeline({
    scrollTrigger: {
        trigger: '.result-card',
        start: 'bottom 90%',
        end: 'bottom 70%',
        scrub: true,
        once: true
    },
})

resultCard.fromTo('.result-card', {
    opacity: 0,
    translateY: '-50px'
}, {
    opacity: 1,
    translateY: '0',
    stagger: 0.2
})

const calculateProfitBox = gsap.timeline({
    scrollTrigger: {
        trigger: '.calculate-profit .animated-box',
        start: 'bottom 90%',
        end: 'bottom 70%',
        scrub: true,
        once: true
    },
})

calculateProfitBox.fromTo('.calculate-profit .animated-box', {
    translateY: '-30px',
    opacity: 0
}, {
    opacity: 1,
    translateY: '0',
})

const calculateProfitBtn = gsap.timeline({
    scrollTrigger: {
        trigger: '.calculate-profit .base-btn',
        start: 'bottom 90%',
        end: 'bottom 70%',
        scrub: true,
        once: true
    },
})

calculateProfitBtn.fromTo('.calculate-profit .base-btn', {
    opacity: 0
}, {
    opacity: 1,
})


const reviews = gsap.timeline({
    scrollTrigger: {
        trigger: '#reviews',
        start: 'top 90%',
        end: 'bottom 70%',
        scrub: true,
        once: true
    },
})

reviews.fromTo('#reviews', {
    opacity: 0,
    translateY: '-50px'
}, {
    translateY: '0',
    opacity: 1,
})


const contactLeftSide = gsap.timeline({
    scrollTrigger: {
        trigger: '#contact-us .left-side',
        start: 'top 90%',
        end: 'top 50%',
        scrub: true,
        once: true
    },
})

contactLeftSide.fromTo('#contact-us .left-side', {
    opacity: 0,
    translateY: '-50px'
}, {
    translateY: '0',
    opacity: 1,
})


const contactRightSide = gsap.timeline({
    scrollTrigger: {
        trigger: '#contact-us .right-side',
        start: 'top 80%',
        end: 'top 50%',
        scrub: true,
        once: true
    },
})

contactRightSide.fromTo('#contact-us .right-side', {
    opacity: 0,
    translateY: '-100px'
}, {
    translateY: '0',
    opacity: 1,
})
    }
})
