import anime from 'animejs/lib/anime.es.js';

const darkBluePaths = document.querySelectorAll('.blue1');
const lightBluePaths = document.querySelectorAll('.blue2');
const darkBluePathsArray = Array.from(darkBluePaths);
const lightBluePathsArray = Array.from(lightBluePaths);

// Generate random values between two number
function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function shuffleArray(arr) {
    let shuffled = arr.sort(function(){return .5 - Math.random()});

    return shuffled;
}


const heroImageTimeline = anime.timeline({
    complete: function() {
      startAnimationDarkBluePaths();
      startAnimationLightBluePaths();
    }
});

heroImageTimeline
    .add({
        targets: darkBluePaths,
        delay: anime.stagger(100),
        fill: ['#F9FAFB', '#0B4BFF']
    })
    .add({
        targets: lightBluePaths,
        delay: anime.stagger(100),
        fill: ['#F9FAFB', '#80BAFF'],
        stroke: ['#E1EDFB','#64ABFF']
    },'-=800');



function startAnimationDarkBluePaths() {
    let arr = shuffleArray(darkBluePathsArray);
    let slicedArray1 = arr.slice(0,15)
    let slicedArray2 = arr.slice(15,24)

    anime({
        targets:  slicedArray1,
        fill: ['#0B4BFF', '#F9FAFB'],
        delay: anime.stagger(300),
        loop: true,
        direction: 'alternate'
    })

    anime({
        targets:  slicedArray2,
        fill: ['#F9FAFB', '#0B4BFF'],
        delay: anime.stagger(300),
        loop: true,
        direction: 'alternate'
    })
}



function startAnimationLightBluePaths() {
    let arr = shuffleArray(lightBluePathsArray);
    let slicedArray1 = arr.slice(0,6)
    let slicedArray2 = arr.slice(6,8)

    anime({
        targets:  slicedArray1,
        fill: ['#F9FAFB', '#80BAFF'],
        stroke: ['#F9FAFB', '#E3EEFB'],
        delay: anime.stagger(1000),
        loop: true,
        direction: 'alternate'
    })

    anime({
        targets:  slicedArray2,
        fill: ['#80BAFF', '#F9FAFB'],
        stroke: ['#F9FAFB', '#E3EEFB'],
        delay: anime.stagger(1000),
        loop: true,
        direction: 'alternate'
    })
}


function restartAnimation() {
    startAnimationDarkBluePaths();
}

function restartAnimationLightBluePath() {
     startAnimationLightBluePaths();
}

